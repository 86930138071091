<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="selection.id">
        <div class="row">
          <div class="col-lg-8">
            <h3>
              semaine {{ selection.week_of_year }}
              <span class="text-muted">
                du {{ $dayjs(selection.start_at).format('DD.MM.YYYY') }}
                au
                {{ $dayjs(selection.end_at).format('DD.MM.YYYY') }}
              </span>
            </h3>
          </div>
          <div class="col-lg-4 text-right"></div>
        </div>
        <b-tabs content-class="mt-2">
          <b-tab title="Propriétés" active>
            <dl class="row">
              <dt class="col-sm-3 text-right">#</dt>
              <dd class="col-sm-9">
                <b><copy-value :value="selection.id" /></b>
              </dd>
              <dt class="col-sm-3 text-right">Statut</dt>
              <dd class="col-sm-9">
                <b>{{ $t(selection.status) }}</b>
              </dd>
              <dt class="col-sm-3 text-right">S’applique</dt>
              <dd class="col-sm-9">
                <b>{{ $dayjs(selection.start_at).fromNow() }}</b>
              </dd>
              <dt class="col-sm-3 text-right">Nutriscores</dt>
              <dd class="col-sm-9">
                <span
                  v-for="(count, key) in selection.recipes_metadata.nutriscores"
                  :key="key"
                  class=""
                  style="margin: 0 5px 5px 0"
                >
                  <b>{{ key }}</b>
                  <small class="badge badge-light text-primary">
                    {{ count }}
                  </small>
                </span>
              </dd>
              <dt class="col-sm-3 text-right">Difficulté cuisson</dt>
              <dd class="col-sm-9">
                <b>{{ selection.recipes_metadata.difficulty }}</b>
              </dd>
              <dt class="col-sm-3 text-right">Difficulté assemblage</dt>
              <dd class="col-sm-9">
                <b>{{ selection.recipes_metadata.assemblage_difficulty }}</b>
              </dd>
              <dt class="col-sm-3 text-right">Moyenne prix de revient</dt>
              <dd class="col-sm-9">
                <b>{{ selection.recipes_metadata.cost_per_unit | currency }}</b>
              </dd>
              <dt class="col-sm-3 text-right">Moyenne taux de marge</dt>
              <dd class="col-sm-9">
                <b>{{ selection.recipes_metadata.margin_percent }}%</b>
              </dd>
              <dt class="col-sm-3 text-right">Plats</dt>
              <dd class="col-sm-9">
                <span
                  v-for="(count, key) in selection.recipes_metadata.dishes"
                  :key="key"
                  class=""
                  style="margin: 0 5px 5px 0"
                >
                  <b>{{ $t(`RECIPE_DISHES.${key}`) }}</b>
                  <small class="badge badge-light text-primary">
                    {{ count }}
                  </small>
                </span>
              </dd>
              <dt class="col-sm-3 text-right">Catégories</dt>
              <dd class="col-sm-9">
                <span
                  v-for="(count, key) in selection.recipes_metadata.categories"
                  :key="key"
                  class=""
                  style="margin: 0 5px 5px 0"
                >
                  <b>{{ $t(`RECIPE_CATEGORIES.${key}`) }}</b>
                  <small class="badge badge-light text-primary">
                    {{ count }}
                  </small>
                </span>
              </dd>
              <dt class="col-sm-3 text-right">Allergènes</dt>
              <dd class="col-sm-9">
                <span
                  v-for="(count, key) in selection.recipes_metadata.allergens"
                  :key="key"
                  class=""
                  style="margin: 0 5px 5px 0"
                >
                  <b>{{ key }}</b>
                  <small class="badge badge-light text-primary">
                    {{ count }}
                  </small>
                </span>
              </dd>
              <dt class="col-sm-3 text-right">Régimes</dt>
              <dd class="col-sm-9">
                <span
                  v-for="(count, key) in selection.recipes_metadata.diets"
                  :key="key"
                  class=""
                  style="margin: 0 5px 5px 0"
                >
                  <b>{{ $t(key) }}</b>
                  <small class="badge badge-light text-primary">
                    {{ count }}
                  </small>
                </span>
              </dd>
            </dl>
            <div v-if="recipesErrors.length > 0" class="bg-danger">
              <p class="text-white text-center">
                <b>
                  La sélection comporte {{ recipesErrors.length }} recette(s)
                  avec des données manquantes
                </b>
              </p>
            </div>
          </b-tab>
          <b-tab title="Métadonnées">
            <pre>{{ selection }}</pre>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <!-- <pre>{{ selection.recipes_metadata }}</pre> -->
    <b-card v-if="selection && selection.id">
      <div class="row mb-2">
        <div class="col-lg-8">
          <h3>
            Recettes
            <small>({{ selection.recipes.length }} sélectionnée(s))</small>
          </h3>
        </div>
        <div class="col-lg-4 text-right">
          <button
            class="btn btn-primary btn-sm"
            @click.prevent="displayRecipeFilters = !displayRecipeFilters"
          >
            <feather-icon icon="PlusIcon" />
          </button>
        </div>
      </div>
      <div v-if="displayRecipeFilters" class="m-lg-5 mb-5">
        <b-overlay :show="loading" rounded="sm">
          <selection-recipes-filters
            :filters.sync="filters"
            @updated="searchRecipes(1)"
          />
          <hr />
          <div class="row">
            <div v-for="recipe in recipes" :key="recipe.id" class="col-lg-4">
              <b-card
                :img-src="recipe.thumb_list_url"
                img-alt="Image"
                img-top
                bg-variant="light"
                class="mb-2 text-center"
              >
                <h5 style="min-height: 8rem">
                  <font-awesome-icon
                    v-if="recipe.is_publishable == false"
                    icon="flag"
                    class="text-danger"
                  />
                  <small>
                    <span
                      v-if="recipe.nutri_score"
                      title="Nutriscore"
                      class="badge badge-info"
                      style="margin: 0 5px 5px 0"
                    >
                      Nutri {{ recipe.nutri_score }}
                    </span>
                    <span
                      v-if="recipe.category"
                      title="Catégorie"
                      class="badge badge-info"
                      style="margin: 0 5px 5px 0"
                    >
                      {{ $t(`RECIPE_CATEGORIES.${recipe.category}`) }}
                    </span>
                    <span
                      v-if="recipe.technical_sheet"
                      title="Catégorie"
                      class="badge badge-info"
                      style="margin: 0 5px 5px 0"
                    >
                      TDM
                      {{ recipe.technical_sheet.margin_percent }}%
                    </span>
                    <span
                      v-if="recipe.difficulty"
                      title="Difficulté cuisson"
                      class="badge badge-info"
                      style="margin: 0 5px 5px 0"
                    >
                      Difficulté cuisson
                      {{ recipe.difficulty }}
                    </span>
                    <span
                      v-if="recipe.assemblage_difficulty"
                      title="Difficulté assemblage"
                      class="badge badge-info"
                      style="margin: 0 5px 5px 0"
                    >
                      Difficulté assemblage
                      {{ recipe.assemblage_difficulty }}
                    </span>
                    <span
                      v-if="recipe.best_seller && recipe.best_seller === true"
                      title="Difficulté assemblage"
                      class="badge badge-success"
                      style="margin: 0 5px 5px 0"
                    >
                      Meilleure vente
                    </span>
                  </small>
                  <br />
                  {{ recipe.label }}
                </h5>
                <button
                  v-if="recipesIds.includes(recipe.id) == false"
                  class="btn btn-sm btn-primary"
                  @click.prevent="select(recipe.id)"
                >
                  <feather-icon icon="PlusIcon" />
                </button>
                <button
                  v-else
                  class="btn btn-sm btn-warning mr-1"
                  @click.prevent="remove(recipe.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </button>
              </b-card>
            </div>
          </div>
          <pagination-custom
            :page="filters.page"
            :total="pagination.total"
            :per-page="filters.per_page"
            class="text-center mt-3"
            @change="searchRecipes"
          />
        </b-overlay>
      </div>
      <div v-else class="row">
        <div
          v-for="recipe in selection.recipes"
          :key="recipe.id"
          class="col-lg-4"
        >
          <b-card
            :img-src="recipe.thumb_list_url"
            img-alt="Image"
            img-top
            bg-variant="light"
            class="mb-2 text-left"
          >
            <h5 style="min-height: 7rem">
              <small>
                <span
                  v-if="recipe.nutri_score"
                  title="Nutriscore"
                  class="badge badge-info"
                  style="margin: 0 5px 5px 0"
                >
                  Nutri {{ recipe.nutri_score }}
                </span>
                <span
                  v-if="recipe.category"
                  title="Catégorie"
                  class="badge badge-info"
                  style="margin: 0 5px 5px 0"
                >
                  {{ $t(`RECIPE_CATEGORIES.${recipe.category}`) }}
                </span>
                <span
                  v-if="recipe.technical_sheet"
                  title="Catégorie"
                  class="badge badge-info"
                  style="margin: 0 5px 5px 0"
                >
                  TDM
                  {{ recipe.technical_sheet.margin_percent }}%
                </span>
                <span
                  v-if="recipe.difficulty"
                  title="Difficulté cuisson"
                  class="badge badge-info"
                  style="margin: 0 5px 5px 0"
                >
                  Difficulté cuisson
                  {{ recipe.difficulty }}
                </span>
                <span
                  v-if="recipe.assemblage_difficulty"
                  title="Difficulté assemblage"
                  class="badge badge-info"
                  style="margin: 0 5px 5px 0"
                >
                  Difficulté assemblage
                  {{ recipe.assemblage_difficulty }}
                </span>
                <span
                  v-if="recipe.best_seller && recipe.best_seller === true"
                  title="Difficulté assemblage"
                  class="badge badge-success"
                  style="margin: 0 5px 5px 0"
                >
                  Meilleure vente
                </span>
              </small>
              <br />
              <feather-icon
                v-if="recipe.is_publishable == false"
                icon="AlertTriangleIcon"
              />
              <span :class="{ 'text-danger': recipe.is_publishable == false }">
                {{ recipe.label }}
              </span>
            </h5>
            <button
              class="btn btn-sm btn-warning mr-1"
              @click.prevent="remove(recipe.id)"
            >
              <feather-icon icon="TrashIcon" />
            </button>
            <a :href="`/recipes/${recipe.id}`" class="btn btn-sm btn-info mr-1">
              <feather-icon icon="EyeIcon" />
            </a>
            <button
              :class="{
                'btn-info': selection.highlighted.includes(recipe.id) == false,
                'btn-success':
                  selection.highlighted.includes(recipe.id) == true,
              }"
              class="btn btn-sm"
              @click.prevent="
                highlight(recipe.id, selection.highlighted.includes(recipe.id))
              "
            >
              <feather-icon icon="StarIcon" />
            </button>
          </b-card>
        </div>
      </div>
    </b-card>
    <!-- <pre>{{ recipesIds }}</pre> -->
    <!-- <pre>{{ selection }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BOverlay } from 'bootstrap-vue'
import SelectionRecipesFilters from './SelectionRecipesFilters.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    SelectionRecipesFilters,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      displayRecipeForm: false,
      displayRecipeFilters: false,
      selection: {},
      recipes: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 8,
        term: null,
      },
    }
  },
  computed: {
    recipesIds() {
      const ids = []
      if (this.selection && this.selection.recipes) {
        this.selection.recipes.forEach((r) => {
          ids.push(r.id)
        })
      }
      return ids
    },
    recipesErrors() {
      const ids = []
      if (this.selection && this.selection.recipes) {
        this.selection.recipes.forEach((r) => {
          if (r.is_publishable === false) {
            ids.push(r.id)
          }
        })
      }
      return ids
    },
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const resp = await this.$http.get(
        `/recipes/selections/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields':
              'id,status,week_of_year,highlighted,recipes_metadata,start_at,end_at,' +
              'recipes{' +
              'id,label,is_publishable,thumb_list_url,category,nutri_score,' +
              'best_seller,difficulty,assemblage_difficulty,technical_sheet{margin_percent}' +
              '}',
          },
        }
      )
      this.selection = resp.data
      this.loading = false
    },
    async searchRecipes(page) {
      if (page) {
        this.filters.page = page
      }
      const resp = await this.$http.get('/recipes/', {
        params: this.filters,
        headers: {
          'X-Fields':
            'results{id,label,is_publishable,thumb_list_url,category,nutri_score,' +
            'best_seller,difficulty,assemblage_difficulty,technical_sheet{margin_percent}},meta',
        },
      })
      this.recipes = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
    },
    async select(id) {
      this.loading = true
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.post(
        `/recipes/selections/${this.$route.params.id}/recipes/`,
        {
          status: 'ENABLED',
          recipe_id: id,
        },
        {
          headers: {
            'X-CSRF-TOKEN': respCsrf.data.csrf,

            'X-Fields': 'id',
          },
        }
      )
      this.init()
      this.loading = false
      console.log(JSON.parse(JSON.stringify(resp.data)))
    },
    async highlight(recipeId, highlight) {
      this.loading = true
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.put(
        `/recipes/selections/${this.$route.params.id}/recipes/${recipeId}/`,
        {
          highlight: !highlight,
        },
        {
          headers: {
            'X-CSRF-TOKEN': respCsrf.data.csrf,

            'X-Fields': 'id',
          },
        }
      )
      this.init()
      this.loading = false
      console.log(JSON.parse(JSON.stringify(resp.data)))
    },
    async remove(recipeId) {
      this.loading = true
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(
        `/recipes/selections/${this.$route.params.id}/recipes/${recipeId}/`,
        {
          headers: {
            'X-CSRF-TOKEN': respCsrf.data.csrf,

            'X-Fields': 'id',
          },
        }
      )
      this.init()
      this.loading = false
      console.log(JSON.parse(JSON.stringify(resp.data)))
    },
  },
}
</script>
<style scoped>
.card-body {
  padding: 1rem !important;
}
</style>
