<template>
  <div>
    <b-form-group label-for="ingredients-term">
      <b-form-input
        id="ingredients-term"
        v-model="options.term"
        placeholder="Recettes par mot clés"
        autofocus
        @change="updateFilters()"
      />
    </b-form-group>
    <b-form-group label-for="tags-component-select">
      <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
      <b-form-tags
        v-if="constants.RECIPE_CATEGORIES.length > 0"
        id="tags-component-select"
        v-model="options.category"
        size="lg"
        class="mb-2"
        add-on-change
        no-outer-focus
        @input="updateFilters()"
      >
        <template
          v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
        >
          <ul class="list-inline d-inline-block mb-2">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag
                :title="tag"
                :disabled="disabled"
                variant="warning"
                @remove="removeTag(tag)"
              >
                {{ $t(`RECIPE_CATEGORIES.${tag}`) }}
              </b-form-tag>
            </li>
          </ul>
          <b-form-select
            :disabled="disabled || constants.RECIPE_CATEGORIES.length === 0"
            v-bind="inputAttrs"
            v-on="inputHandlers"
          >
            <template #first>
              <option disabled value="">
                -- Choisir parmi les catégories --
              </option>
            </template>
            <b-form-select-option
              v-for="option in constants.RECIPE_CATEGORIES"
              :key="option"
              :value="option"
            >
              {{ $t(`RECIPE_CATEGORIES.${option}`) }}
            </b-form-select-option>
          </b-form-select>
        </template>
      </b-form-tags>
    </b-form-group>
    <div class="row">
      <div class="col-lg-12">
        <b-form-group>
          <b-form-checkbox-group
            v-model="options.season"
            name="saisons"
            @change="updateFilters()"
          >
            <b-form-checkbox
              v-for="option in constants.RECIPE_SEASONS"
              :key="option"
              :value="option"
            >
              {{ $t(option) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="col-lg-12">
        <b-form-group>
          <b-form-checkbox-group
            v-model="options.dish"
            name="Type de plat"
            @change="updateFilters()"
          >
            <b-form-checkbox
              v-for="option in constants.RECIPE_DISHES"
              :key="option"
              :value="option"
            >
              {{ $t(`RECIPE_DISHES.${option}`) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <!-- <pre>{{ options }}</pre> -->
    <!-- <pre>{{ constants }}</pre> -->
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormTags,
  BFormTag,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'

export default {
  name: 'SelectionsRecipesFilters',
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormTags,
    BFormTag,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      constants: {
        RECIPE_SEASONS: [],
        RECIPE_CATEGORIES: [],
      },
      options: {
        category: [],
        season: null,
        dish: null,
        scheme: null,
        term: null,
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: {
          keys: 'RECIPE_SEASONS,RECIPE_CATEGORIES,RECIPE_DISHES,RECIPE_SCHEMES',
        },
      })
      this.constants = respConstants.data
      this.options = JSON.parse(JSON.stringify(this.$route.query))
    },
    updateFilters() {
      this.$emit('update:filters', Object.assign(this.options, this.filters))
      this.$emit('updated', Object.assign(this.options, this.filters))
    },
  },
}
</script>
